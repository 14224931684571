import React from 'react'

const DateDisplay = ({ startDate, endDate }) => {
  const formatStartDate = (startDate, endDate) => {
    if (startDate.split(' ').pop() === endDate.split(' ').pop()) {
      const startDateArr = startDate.split(' ')
      return startDateArr[0]
    }
    else
      return startDate
  }

  return (
    <div className='c-date'>
      {endDate ? `${formatStartDate(startDate, endDate)} -` : startDate}{' '}{endDate && endDate}
    </div>
  )
}

export default DateDisplay