import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import DateDisplay from './DateDisplay';

export function EventCard(props) {



    return (<Link to={props.url}><div className={`o-pillar o-pillar--teaser [ group ]`}>
        {props.data.image && <div className={`o-pillar__image`}>
            <GatsbyImage image={getImage(props.data.image.gatsbyImageData)} alt="" className={`o-pillar--image`} />
            {props.data.category?.document && <div className={`o-pillar__label o-pillar__label--event`}>
                {props.data.category.document.data.title}
            </div>}
        </div>}
        <div className={`o-pillar__main`}>
            <h3 className={`o-pillar__title`}>{props.data.title}</h3>
            <DateDisplay endDate={props.data.end_date} startDate={props.data.start_date} />

            <div className={`o-pillar__content`}>
                <RichText render={props.data.short_description.raw} />
            </div>
        </div>
    </div>
    </Link>);
}
