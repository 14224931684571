import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import React from 'react'
import PageFooter from '../components/PageFooter'
import PageHeader from '../components/PageHeader'
import { linkResolver } from '../utils/linkResolver'
import { Event } from '../components/Event';
import { usePageContext } from '../utils/PageContext'
import { Head } from '../components/Head'

const PageTemplate = ({ data, pageContext }) => {
  const { lang } = usePageContext();

  if (!data) return null

  const document = data.prismicEventcategory
  const navigation = data.prismicMultiLevelNavigation.data.body
  const pageLabel = { spans: [], text: document?.data.title, type: "heading1" }
  const events = pageContext.filter ? data.filtered.edges : data.unfiltered.edges;


  return (
    <>
      <Head lang={lang} uid={document.uid} title={document.data.title}></Head>
      <PageHeader navigation={navigation} lang={pageContext.lang} altLangs={document.alternate_languages} />
      <Event slice={{ slice_label: 'overview', primary: { subhead: { raw: [pageLabel] } } }} items={events} />
      <PageFooter socials={data.prismicConfiguration} navigation={navigation} />
    </>
  )
}

export const query = graphql`
    query EventPageQuery($lang: String, $filter: String , $uid: String) {
      ... MenuQuery
      
      filtered: allPrismicEvent(
        filter: {data: {category: {uid: {eq: $filter}}}, lang: {eq: $lang}}
        sort: {fields: data___date, order: DESC}
        ) {
        edges {
          node {
            _previewable
            data {
              short_description {
                raw
              }
              image {
                gatsbyImageData(srcSetMaxWidth: 754, width: 754, height: 430)
                alt
              }
              title
            }
            url
          }
        }
    }

    unfiltered: allPrismicEvent(
      filter: {lang: {eq: $lang}}
      sort: {fields: data___date, order: DESC}
      ) {
      edges {
        node {
          _previewable
          data {
            short_description {
              raw
            }
            image {
              gatsbyImageData(srcSetMaxWidth: 754, width: 754, height: 430)
              alt
            }
            title
            start_date(formatString: "DD  MMMM", locale: $lang)
            end_date(formatString: "DD  MMMM", locale: $lang)
          }
          url
        }
      }
  }

    prismicEventcategory(uid: {eq: $uid}) {
        data {
          title
        }
        alternate_languages {
          id
          type
          lang
          uid
        }
      }

      prismicConfiguration(lang: { eq: "nl-be" }) {
        data {
          facebook {
            url
          }
          linkedin {
            url
          }
        }
      }

    }
  
`

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver
  },
])